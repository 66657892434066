<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loadingState">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                <div class="row">
                  <b-col md="6">
                      <ValidationProvider name="Project Id" vid="project_id" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="project_id" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.project_id')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="project_id"
                                v-model="proposal.project_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Thematic area" vid="thematic_area" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="thematic_area" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.thematic_area')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="thematic_area"
                                v-model="proposal.thematic_area"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Sector name" vid="sector_name" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="sector_name" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.sector_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="sector_name"
                                v-model="proposal.sector_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="SubSector name" vid="sub_sector_name" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="sub_sector_name" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.sub_sector_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="sub_sector_name"
                                v-model="proposal.sub_sector_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Project title" vid="project_title" rules="required">
                        <b-form-group class="row" label-cols-sm="4" label-for="project_title" slot-scope="{ valid, errors }">
                            <template v-slot:label>
                            {{$t('external_research.project_title')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                disabled
                                plain
                                id="project_title"
                                v-model="proposal.project_title"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            </b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider name="Completion Report" vid="completion_report">
                      <b-form-group class="row" label-cols-sm="4" label-for="completion_report" slot-scope="{ valid, errors }">
                          <template v-slot:label>
                          {{ $t('external_research.completion_report') }}
                          <!-- <v-slot v-if="main_form.completion_report !== null">
                              <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.completion_report" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                          </v-slot> -->
                          </template>
                          <b-form-file
                          id="completion_report"
                          @change="onPhotoChange"
                          v-model="proposal.attachment"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          </b-form-file>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                    <ValidationProvider name="Remarks(En)" vid="remarks_en" >
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="remarks_en"
                            >
                              <template v-slot:label>
                                  {{$t('certify_form.remark_en')}}
                              </template>
                              <b-form-textarea
                                  id="remarks_en"
                                  v-model="proposal.remarks_en"
                                  class="mb-3"
                                  name="remarks_en"
                              >
                              </b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                    <ValidationProvider name="Remarks(Bn)" vid="remarks_bn" >
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="remarks_bn"
                            >
                                <template v-slot:label>
                                    {{$t('certify_form.remark_bn')}}
                                </template>
                                <b-form-textarea
                                    id="remarks_bn"
                                    v-model="proposal.remarks_bn"
                                    class="mb-3"
                                    name="remarks_bn"
                                >
                                </b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </div>
                <div class="row">
                  <b-col class="text-right">
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                      &nbsp;
                    <b-button type="submit" variant="warning" @click="getStatus('3')" class="mr-2">{{ $t('globalTrans.reject') }}</b-button>
                    &nbsp;
                    <b-button type="submit" variant="primary" @click="getStatus('2')" class="mr-2">{{ $t('globalTrans.approved') }}</b-button>
                  </b-col>
                </div>
            </b-form>
          </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { approvedOrReject, getProposalData } from '../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['item'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      defaultValue: '',
      proposal: {
        proposal_id: 0,
        thematic_area: '',
        project_id: '',
        sector_name: '',
        sub_sector_name: '',
        project_title: '',
        attachment: [],
        file: '',
        remarks_en: '',
        remarks_bn: '',
        status: ''
      },
      proposal_id: 0
    }
  },
  computed: {
    loadingState () {
        if (this.listReload) {
        return true
        } else if (this.loading) {
            return true
        } else {
        return false
        }
    }
  },
  created () {
    if (this.item) {
        this.getData(this.item)
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getStatus (value) {
        this.proposal.status = value
    },
    getData () {
      RestApi.postData(agriResearchServiceBaseUrl, getProposalData, { proposal_id: this.item.id })
      .then(response => {
        if (response.success) {
          this.getRelationalData(response.data)
          this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
      })
    },
    getRelationalData (data) {
      this.proposal.proposal_id = this.item.id
      this.proposal.project_id = this.item.proposal_auto_id
      this.proposal.project_title = this.item.project_title
      this.proposal.remarks = this.$i18n.locale === 'en' ? data.report_submission.remarks_en : data.report_submission.remarks_bn
      const sector = this.$store.state.AgriResearch.commonObj.sectorList.find(newItem => newItem.value === parseInt(data.invitation.id))
      const circular = this.$store.state.AgriResearch.commonObj.circularList.find(newItem => newItem.value === parseInt(data.circular_main_id))
      const thematic = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(newItem => newItem.value === parseInt(circular.thematic_area_id))
      if (this.$i18n.locale === 'bn') {
        this.proposal.thematic_area = thematic.text_bn
        this.proposal.sector_name = sector.text_bn
      } else {
        this.proposal.sector_name = sector.text_en
        this.proposal.thematic_area = thematic.text_en
      }
      data.invitation.proposal_invitation_details.map(item => {
        const subSector = this.$store.state.AgriResearch.commonObj.subSectorList.find(newItem => newItem.value === parseInt(item.sub_sector_id))
        if (this.$i18n.locale === 'bn') {
          this.proposal.sub_sector_name = subSector.text_bn
        } else {
          this.proposal.sub_sector_name = subSector.text_en
        }
      })
    },
    onPhotoChange (event) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.proposal.file = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.proposal.file = ''
      }
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(agriResearchServiceBaseUrl, approvedOrReject, this.proposal)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
