<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('external_research.research_report_submission') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(report_submit)="data">
                      <span class="badge badge-danger" v-if="data.item.proposal.is_report_submit === 1">{{ $t('globalTrans.pending') }}</span>
                      <span class="badge badge-success" v-if="data.item.proposal.is_report_submit === 2">{{ $t('globalTrans.approved') }}</span>
                      <span class="badge badge-danger" v-if="data.item.proposal.is_report_submit === 3">{{ $t('globalTrans.rejected') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <!-- <b-button class="mr-1" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" title="Edit" @click="viewApplication(data.item)"><i class="ri-edit-line"></i></b-button> -->
                        <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="details(data.item)">
                            <i class="ri-eye-line m-0"></i>
                        </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <FormV :item="application" :key="editItemId"/>
    </b-modal>
       <b-modal id="modal-1" size="lg" :title="this.$t('certify_form.application_details')"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>

<script>
import FormV from './Form.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { getApprovedApplicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
import Details from './Details'
export default {
    components: {
        FormV, Details
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                name: ''
            },
            application: {},
            datas: [],
            viewItemId: 0,
            editItemId: 0,
            info: {
                user_id: this.$store.state.Auth.authUser.id
            }
        }
    },
    computed: {
        formTitle () {
            return this.$t('certify_form.application_details')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                // { label: this.$t('external_research.circular_name'), class: 'text-left' },
                { label: this.$t('external_research.project_id'), class: 'text-left' },
                { label: this.$t('external_research.project_title'), class: 'text-left' },
                { label: this.$t('external_research.thematic_area'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    // { key: 'circular_name_bn' },
                    { key: 'proposal.proposal_auto_id' },
                    { key: 'proposal.project_title_bn' },
                    { key: 'thematic_area_bn' },
                    { key: 'report_submit' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    // { key: 'circular_name' },
                    { key: 'proposal.proposal_auto_id' },
                    { key: 'proposal.project_title' },
                    { key: 'thematic_area' },
                    { key: 'report_submit' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
      async searchData () {
          this.loadData()
      },
        viewApplication (item) {
            this.application = item
        },
        details (item) {
            // this.application = item
            this.editItemId = item.id
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.info, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, getApprovedApplicationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const listData = data.map(item => {
                // const circular = this.$store.state.AgriResearch.commonObj.circularList.find(newItem => newItem.value === parseInt(item.circular_main_id))
                const thematic = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(newItem => newItem.value === parseInt(item.proposal.invitation.thematic_area_id))
                const newData = {
                    thematic_area: thematic !== undefined ? thematic.text_en : '',
                    thematic_area_bn: thematic !== undefined ? thematic.text_bn : ''
                }
                return Object.assign({}, item, newData)
            })
            return listData
        }
    }
}
</script>
