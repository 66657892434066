<template>
  <b-container fluid class="text-dark">
    <b-row >
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay>
              <b-table-simple bordered small hover class="request_dtls_wrapper">
                <tbody>
                  <b-tr>
                      <b-th>{{$t('external_research.project_id')}}</b-th>
                      <b-td>{{ proposal.proposal.proposal_auto_id }} </b-td>
                      <b-th>{{$t('external_research.thematic_area')}}</b-th>
                      <b-td>{{ currentLocale == 'bn' ? proposal.thematic_area_bn : proposal.thematic_area }}</b-td>
                  </b-tr>
                  <!-- <b-tr>
                      <b-th>{{$t('external_research.sector_name')}}</b-th>
                      <b-td>{{ proposal.sector_name }} </b-td>
                      <b-th>{{$t('external_research.sub_sector_name')}}</b-th>
                      <b-td>{{ proposal.sub_sector_name }} </b-td>
                  </b-tr> -->
                  <b-tr>
                      <b-th>{{$t('external_research.project_title')}}</b-th>
                      <b-td>{{ $i18n.locale === 'en' ? proposal.proposal.project_title : proposal.proposal.project_title_bn }}</b-td>
                       <b-th>{{ $t('external_research.completion_report') }}</b-th>
                      <b-td>
                        <a target="_blank" v-if="proposal.attachment" :href="url + 'storage/report-submission/' + proposal.attachment">
                          <i class="fa fa-cloud" aria-hidden="true"></i>
                          {{ $t('globalTrans.attachment') }}
                        </a>
                      </b-td>
                  </b-tr>
                  <b-tr>
                      <b-th>{{$t('certify_form.remarks')}}</b-th>
                      <b-td colspan="3">{{ proposal.remarks_en }}</b-td>
                  </b-tr>
                </tbody>
              </b-table-simple>
              <b-row>
                <b-col class="text-right">
                  <b-button v-if="proposal.proposal.is_report_submit === 1" v-b-modal.modal-4 variant="iq-bg-success" class="btn btn-success mr-1" size="sm" title="approve" @click="note(proposal, status=2)">{{ $t('globalTrans.approve') }}</b-button>
                  <b-button v-if="proposal.proposal.is_report_submit === 1" v-b-modal.modal-4 variant="iq-bg-danger" class="btn btn-danger" size="sm" title="Reject" @click="note(proposal, status=3)">{{ $t('globalTrans.reject') }}</b-button>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('research_manage.review_remark')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <p>
            <Remarks :remarks="remarks" :key="itemId"/>
        </p>
    </b-modal>
  </b-container>
</template>
<script>
import Remarks from './Remarks'
import { core } from '@/config/pluginInit'
import { agriResearchServiceBaseUrl } from '@/config/api_config'
// import { getProposalData } from '../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    Remarks
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      url: agriResearchServiceBaseUrl,
      proposal: {},
      remarks: {},
      itemId: 0
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  created () {
    if (this.id) {
        const tmp = this.getProjectData()
        this.proposal = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    note (item, status) {
      this.remarks.proposal_id = item.proposal.id
      this.remarks.id = item.id
      this.remarks.newStatus = status
      this.remarks.remarks = ''
      this.itemId = item.id
    },
    getProjectData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
